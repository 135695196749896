import * as React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import PageLayout from "../layouts/PageLayout"
import { parseHtml } from "../utils/parseHtml"

export const pageQuery = graphql`
  query {
    page: wpPage(slug: { eq: "404-2" }) {
      seo {
        canonical
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        twitterTitle
        twitterDescription
        twitterImage {
          sourceUrl
        }
        opengraphType
        opengraphTitle
        opengraphDescription
        opengraphImage {
          sourceUrl
        }
        schema {
          raw
        }
      }
      id
      title
      date(formatString: "MMMM DD, YYYY")
      content
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`

function NotFoundPage({ data: { page } }) {
  return (
    <PageLayout>
      <Seo
        title={page.seo.title}
        description={page.seo.metaDesc}
        twitterTitle={page.seo.twitterTitle}
        twitterDescription={page.seo.twitterDescription}
        twitterImage={page.seo.twitterImage}
        opengraphTitle={page.seo.opengraphTitle}
        opengraphDescription={page.seo.opengraphDescription}
        opengraphType={page.seo.opengraphType}
        opengraphImage={page.seo.opengraphImage}
        schema={page.seo.schema}
        metaRobotsNoindex={page.seo.metaRobotsNoindex}
        metaRobotsNofollow={page.seo.metaRobotsNofollow}
        canonical={page.seo.canonical}
      />
      <>{parseHtml(page.content)}</>
    </PageLayout>
  )
}

export default NotFoundPage
